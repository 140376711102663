const NAME = 'windshieldRepairabilityProfiles';

const DRIVER_FIELD_OF_VIEW = {
  RIGHT: 0,
  LEFT: 1,
  CENTER: 2
};

const DRIVER_FIELD_OF_VIEW_NAME = {
  [DRIVER_FIELD_OF_VIEW.RIGHT]: 'Right',
  [DRIVER_FIELD_OF_VIEW.LEFT]: 'Left',
  [DRIVER_FIELD_OF_VIEW.CENTER]: 'Center'
};

export default {
  NAME,
  DRIVER_FIELD_OF_VIEW,
  DRIVER_FIELD_OF_VIEW_NAME
};
